@import "react-calendar/dist/Calendar.css";
@import url("https://fonts.googleapis.com/css?family=Oswald");

* {
  font-family: Zilla Slab, Lora, georgia, times, serif !important;
  font-weight: 500 !important;
  letter-spacing: normal !important;
}
h1 {
  font-size: 1.5rem !important;
}
.search-results {
  font-size: 1.125rem;
}

.font-heading {
  font-size: 32px !important;
  font-family: Roboto, HelveticaNeue, sans-serif !important ;
  letter-spacing: 0.53px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  text-transform: uppercase;
}
.font-body {
  font-family: Roboto, HelveticaNeue, sans-serif !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  text-align: left !important;
  line-height: 2.1 !important;
  color: black !important;
}
.font-link {
  font-family: Roboto, HelveticaNeue, sans-serif !important;
  font-weight: 400 !important;
  text-align: left !important;
  line-height: 2.1 !important;
  color: #da291c !important;
  text-decoration: none;
}
.font-feedback-body * {
  font-family: Open Sans, Arial, sans-serif !important;
  font-size: 20px !important;
}
.font-roborto {
  font-size: 12px !important;
  font-family: Roboto Condensed, Arial Narrow, arial, sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px !important;
  font-weight: 900 !important;
  padding: .6rem .8rem !important;
}
.how-to-use * {
  font-size: 18px;
}
.and-or-button {
  font-size: 12px !important;
  font-family: Roboto Condensed, Arial Narrow, arial, sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px !important;
  font-weight: 900 !important;
  padding: 5px 5px !important;
}
.font-date * {
  font-size: 14px !important;
  font-family: Roboto Condensed, Arial Narrow, arial, sans-serif !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff !important;
}
.info-tip {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 13px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.selected-and-or {
  color: #ffffff;
}
.form-select {
  cursor: pointer;
}

.styled-link {
  float: right;
  color: #2196fe;
  transition: all 0.25s ease-in;
}
.styled-link:hover,
.styled-link:focus,
.styled-link:active {
  color: #536dfe;
}
.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.red-ribbon-bottom {
  border-bottom: 5px solid #da291c;
}
.red-hover {
  transition: all 0.2s ease-in;
  border-bottom: 5px solid transparent;
  /* cursor: pointer; */
  margin-top: 4vh;
  border-left: 3px solid white;
}
.red-hover:hover {
  /* transform: translateX(0.4rem); */
  /* border: none; */
  /* border: 2px solid #536dfe; */
  border-left: 3px solid rgb(218, 41, 28);

  /* border-right: 4px solid red; */

  /* border-top: 5px solid red; */
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.upper-border {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.searchbox-border {
  border: 3px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
  box-shadow: black;
}

.link {
  text-decoration: none;
  color: inherit;
}
.link:hover {
  text-decoration: none;
  color: inherit;
}

.close {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

/* .modal {
  padding: 0 !important; 
} */
.img-preview {
  /* float: left; */
  min-height: 75vh !important;
  max-height: 75vh !important;
  object-fit: contain;
  min-width: auto;
  max-width: auto;
}
/* .modal .modal-dialog { */
/* size: 7in 9.25in; */
/* margin: 27mm 16mm 27mm 16mm; */
/* min-height: 100px;
  min-width: 100px;
  object-fit: cover; */
/* to centre page on screen*/
/* margin-left: auto;
  margin-right: auto; */

/* width: 50%;
  max-width: none; */
/* height: 100%; */
/* margin: 0; */
/* } */
.modal-header .btn-close {
  /* border: 3px solid rgba(166, 15, 15, 0); */
  /* background-color: rgba(52, 48, 48, 0.482); */
  color: #000 !important;
}
.btn-close {
  opacity: 1 !important;
  padding-right: 0.5vw !important;
  margin-bottom: 0.1vh !important;
}
.btn-close:hover {
  opacity: 0.7 !important;
}

/* .modal-dialog {
  width: 100% !important;
} */
.modal {
  max-width: auto !important;
  min-width: auto !important;
  max-height: auto !important;
  min-height: auto !important;
}
.modal-content {
  max-width: auto !important;
  min-width: auto !important;
  max-height: auto !important;
  min-height: auto !important;
  width: 100% !important;
  /* transform: translateY(-1.2%) !important; */
  /* top: 50% !important; */
  /* min-height: 90vh;
  max-height: 90vh; */
  /* max-width: 60vw;
  min-width: 60vw; */
  /* display: flex;
  justify-content: center; */
  /* overflow-y: auto; */
  /* border: 0; 
  border-radius: 0; */
}
/* .modal .modal-body {
  overflow-y: hidden;
} */
.carousel-control-next {
  margin-right: -17%;
  /* margin-bottom: 8vh; */
  background-color: #000 !important;
  height: 6%;
  margin-top: 35vh;
  border-radius: 50%;
  width: 9% !important;
  opacity: 1 !important;
  padding: 2% !important;
}

.carousel-control-prev {
  margin-left: -17%;
  /* margin-bottom: 8vh; */
  background-color: #000 !important;
  height: 6%;
  margin-top: 35vh;
  border-radius: 50%;
  padding: 2% !important;
  /* height: 50%; */
  width: 9% !important;
  opacity: 1 !important;
}
.carousel-control-next:hover {
  opacity: 0.7 !important;
}
.carousel-control-prev:hover {
  opacity: 0.7 !important;
}

.carousel-indicators {
  /* border-bottom: none !important; */
  background-color: #000;
  margin: 0rem -1.08rem -1.8rem -1.08rem !important;
}

.carousel-indicators [data-bs-target] {
  width: 10% !important;
}

/* .navbar-toggler {
  border-color: white !important;
  padding-left: 2vw !important;
} */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 22px;
  left: 20px;
  top: 1.5vh;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #da291c !important;
  opacity: 1 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 100% !important;
  /* top: 10px !important; */
  /* top: 2% !important; */
  /* left: 0 !important; */
  background: none !important;
  cursor: pointer;
  padding: 18.5px !important;
  border-bottom: 1px solid rgb(153, 153, 153);
  right: 0.5px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  width: 27px !important;
  height: 3px !important;
  top: 1vh !important;
  right: 160px !important;
  background: #000 !important;
}
.bm-cross-button:hover .bm-cross {
  background: #da291c !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: auto !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 4em 5em 0 1em;
  font-size: 1.15em;
  width: auto;
  /* border-right: 1px solid black; */
}

.menu-item-active {
  color: #da291c;
}
.menu-item-active:hover {
  color: #da291c;
}
.menu-item {
  text-decoration: none;
  color: black;
}
.menu-item:hover {
  text-decoration: none;
  color: #da291c;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #999999;
}

/* Wrapper for item list */
/* .bm-item-list {
} */

/* Individual item */
.bm-item {
  /* display: inline-block; */
  color: #282f2f;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}
.bm-item:hover {
  color: #da291c;
}

/* Styling of overlay */
.bm-overlay {
  /* top: 6%; */
  background: rgba(0, 0, 0, 0.7) !important;
}

.page-link {
  color: #000 !important;
}

.btn-dark:hover {
  opacity: 0.7 !important;
}

.btn-light:hover {
  opacity: 0.7 !important;
}

.search-input {
  font-style: italic !important;
  font-weight: 500 !important;
  color: #4d4d4d !important;
  font-size: medium !important;
}
.react-calendar__tile--active {
  background: #e6e6e6 !important;
  color: #000 !important;
}
.react-calendar {
  width: 65% !important;
}
